




















import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Error extends Vue {
  @Prop({ default: null })
  public retry!: Function;

}
