



























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Error from "./Error.vue";

@Component({
  components: { Error }
})
export default class Loader extends Vue {
  private loading = true;

  private error = false;

  @Prop({ default: null })
  public retry!: () => Promise<any>;
  async mounted() {
    await this.onRetryChanged();
  }
  @Watch("retry")
  public async onRetryChanged() {
    if (this.retry) {
      this.error = false;
      this.loading = true;
      try {
        await this.retry();
      } catch (e) {
        this.error = true;
        throw e;
      } finally {
        this.loading = false;
      }
    }
  }
}
