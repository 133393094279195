





















































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { saveAs } from "file-saver";
import FileInput from "@/components/FileInput.vue";

@Component({
  components: {
    Loader,
    FileInput
  }
})
export default class HouseholdMemberAttachments extends Vue {
  @Prop()
  public id!: string;

  private generalattachments: any = [];
  private legalattachments: any = [];
  private psycoattachments: any = [];
  private upload = false;
  private file: any = null;
  private member: any = null;
  private currentUser: any;
  public isLawyer = false;
  public isPsychologist = false;

  async created() {
    this.currentUser = await this.$service.providers.users.fetchItemAsync(
      this.$auth.userInfo.profile.sub
    );
    this.isLawyer = this.$auth.permissions.admin.is || this.currentUser.isLawyer;
    this.isPsychologist =  this.$auth.permissions.admin.is || this.currentUser.isPsychologist;
  }

  async loadAttachments() {
    const member = (
      await this.$service.providers.householdMembers.fetchItemAsync(this.id)
    );

    const attachments = member.files.concat(member.legalFiles)

    this.generalattachments = attachments.filter((a: any) => a.fileType == "General");
    this.legalattachments = attachments.filter((a: any) => a.fileType == "Legal");
    this.psycoattachments = attachments.filter((a: any) => a.fileType == "Psycosocial");    
  }

  async download(s: string) {
    this.downloadBlob(await this.$service.downloadFile(s), s);
  }
  private downloadBlob(blob: Blob, name: string) {
    saveAs(
      blob,
      name
        .split("-")
        .slice(5)
        .join("-")
    );
  }
  
  async remove(id: string) {
    await this.$service.deleteFile(id);
    await this.loadAttachments();
  }


  async uploadFile() {
    const crud = this.$service.crud.uploadedFiles;
    this.file.householdMemberId = this.id;
    await crud.addAsync([this.file]);
    this.file = null;
    await this.loadAttachments();
    this.upload = false;
  }
}
